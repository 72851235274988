@import './_variables.scss';

.LoadingSpinner {
  width: 3rem;
  height: 3rem;
  border: 5px solid #ccc;
  border-top-color: $primary;
  border-radius: 100%;
  /* Center wherever it's contained */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  visibility: hidden;
  /* Animate when shown */
  &.show {
    visibility: visible;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
