@import './_variables.scss';

html {
    font-family: sans-serif;
}

@font-face {
    font-family: "MWF-MDL2";
    src: url("https://www.microsoft.com/mwf/_h/v3.54/mwf.app/fonts/mwfmdl2-v3.54.woff2") format("woff2"), url("https://www.microsoft.com/mwf/_h/v3.54/mwf.app/fonts/mwfmdl2-v3.54.woff") format("woff"), url("https://www.microsoft.com/mwf/_h/v3.54/mwf.app/fonts/mwfmdl2-v3.54.ttf") format("truetype"), url("https://www.microsoft.com/mwf/_h/v3.54/mwf.app/fonts/mwfmdl2-v3.54.svg") format("svg")
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2') format("woff2"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff') format("woff"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf') format("ttf");
    font-weight: 400
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Light/latest.woff2') format("woff2"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Light/latest.woff') format("woff"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Light/latest.ttf') format("ttf");
    font-weight: 100
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Semilight/latest.woff2') format("woff2"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Semilight/latest.woff') format("woff"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Semilight/latest.ttf') format("ttf");
    font-weight: 200
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Semibold/latest.woff2') format("woff2"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Semibold/latest.woff') format("woff"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Semibold/latest.ttf') format("ttf");
    font-weight: 600
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Bold/latest.woff2') format("woff2"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Bold/latest.woff') format("woff"), url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/Bold/latest.ttf') format("ttf");
    font-weight: 600
}


body {
    font-size: 15px;
    font-family: Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

img.logo {
    width: 108px;
}

.navbar-light .navbar-nav .nav-link {
    color: #343a40;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label,
.invalid-feedback {
    color: #A31A2A;
}

.btn-fivebyfive {
	color: #212529
}

.btn-fivebyfive.focus,
.btn-fivebyfive:focus {
	color: #212529;
	box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5)
}

.btn-primary {
    background-color: $primary;
    border-color: $primary
}

a,
.btn-link {
    color: #006fc9 ;

    &:hover,
    &:active,
    &:visited {
        color: #006fc9 
    }

    &:focus {
        text-decoration: underline;
    }
}

.claim-card {
    .card-header {
        color: #f8f9fa !important;
        background-color: #343a40 !important;

        &.active {
            background-color: $primary  !important;
        }
    }
}

.chat-container {
    height: 700px;
}

.ask-container {
    height: 600px;
}

.text-primary {
    color: #0062ce !important;

}

.text-secondary {
    color: #606970 !important;

}

@media (max-width: 768px) {
    .chat-container {
        height: 650px;
    }
}

@media (min-width: 768px) {
    .border-md-right {
        border-right: 1px solid #dee2e6 !important;
    }

    .border-md-left {
        border-left: 1px solid #dee2e6 !important;
    }
}

.dropzone {
    width: 100%;
    min-height: 100px;
    border-width: 2px;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
    border-radius: 5px;
    margin: 5px;

    &:focus {
        border-color: #80bdff;
        outline: 0;
        border-style: solid;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
    }
}

select.empty {
    color: #636c72;

    option {
        color: initial;
    }
}

.no-error-icon {
    .form-control.is-invalid {
        background-image: none;
    }
}

.terms {
    ol {
        li {
            margin-bottom: 10px;
        }

        ol {
            list-style: lower-roman;

            ol {
                list-style: lower-alpha
            }
        }
    }

    ul {
        list-style: disc;
    }
}

.popover {
    //left: -450px !important;
    min-width: 463px !important;
    width: auto;

    img {
        margin-left: -3px;
    }
}

.sticky-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

header {
    .nav-link.active {
        font-weight: bold;
        text-decoration: underline;
        color: #343a40 !important;
    }

    .nav-link:focus {
        text-decoration: underline;
    }
}

.banner-header {
    //background-color: #dfdfdf;
    background-color: #f8f9fa;

    .banner {
		display: block;
		width: 100%;
    }

    .header {
        h1 {
            color: $primary;
        }

        img.logo {
            display: none;
        }

        img.icon {
            margin: 1rem 0.5rem;
            width: 35px;
            height: 35px;
        }

        .offer {
            font-size: medium;
        }
    }

}

@media (min-width:576px) and (max-width:767px){
	.banner-header {

        .banner {
            width: 45%;
			height: 100%;
			display: inline;
        }

        .header {
			width: 55%;
			float: right;
			height: 100%;
			padding-left: 1rem !important;
			.offer {
                font-size: large;
				text-align: left !important;
            }
		}
        
    }
}

@media (min-width:768px) and (max-width:991px){
	.banner-header {

        .banner {
            width: 35%;
			display: inline;
        }

        .header {
			width: 65%;
			float: right;
			height: 100%;
			padding-left: 1rem !important;
			h1 {
                font-size: 3rem;
            }

            h2 {
                font-size: 1.8rem;
            }
		}
        
    }
}

@media (min-width: 768px) {
    .banner-header {
        .header {
            .offer {
                font-size: large;
            }
        }
    }
}

@media (min-width: 992px) {
    .banner-header {

        .banner {
            display: block;
            width: 21rem;
			float: left;
        }

        .header {
            h1 {
                font-size: 3rem;
            }

            h2 {
                font-size: 1.8rem;
            }

            img.logo {
                display: block;
                width: 150px;
            }

            img.icon {
                margin: 1rem 0.5rem;
                width: 50px;
                height: 50px;
            }

            .offer {
                font-size: large;
            }
			height: 100%;
        }
    }
}

.locales-page {
    a.active {
        font-weight: bold;
    }
}

.how-it-works {
    img {
        width: 100%;
        max-width: 490px;
    }
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
	color: #a31a2a
}

.custom-control-input:not(:disabled):active~.custom-control-label::before,
.form-check-input,
.form-control,
.custom-select {
    border-color: #4B5962;
}

.form-control:focus,
.custom-select:focus {
    border-color: #0056B3;
}

@media (forced-colors: active) {

    .btn-primary:focus,
    .btn-primary.focus,
    .btn-danger:focus,
    .btn-danger.focus,
    .btn-fivebyfive.focus,
    .btn-fivebyfive:focus,
	.btn-secondary:focus,
    .btn-secondary.focus {
        outline: 3px solid transparent;
    }
}

.container-full {
    min-height: calc(100vh - 145px);
}


.custom-typeahead input.rbt-input-main.form-control {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
}

.custom-typeahead.is-invalid input.rbt-input-main.form-control {
    border-color: #dc3545;
    padding-right: calc(.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
}

input.rbt-input:disabled {
    color: #6c757d !important;
    background-color: #e9ecef !important;
}

.rbt-menu>.dropdown-item {
    color: #212529 !important;
}

.custom-disabled {
    color: #495057 !important;
    background: #fff !important;
    border-color: #fff !important;
}